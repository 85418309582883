import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError, AxiosResponse } from 'axios';

import CreateTrackModal from './modals/CreateTrack.modal';
import EditTrackModal from './modals/EditTrack.modal';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { AxiosClient } from 'utils/axios';
import { ShowItemTrack } from 'types/ShowItem';
import { ShowModalCreateTrack, ShowModalEditTrack } from 'store/show.page.slice';

function ListTrack() {
    const { id } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const forceReload = useAppSelector((state) => state.pageShow.listing.forceReloadTracks);
    const [items, setItems] = useState<ShowItemTrack[]>([]);

    useEffect(() => { 
        if(!id) return 

        dispatch(SetLoading(true));
        AxiosClient.get(`/show-items/${id}/tracks`, { params: { paginationLimit: 100, sort: 'sortOrder:asc,id:asc' } }).then((res: AxiosResponse) => {
            let items: ShowItemTrack[] = res.data.data;

            setItems(items);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            console.log(err);
            dispatch(SetLoading(false));
            navigate('/content/shows'); 
        });
    }, [id, forceReload]);

    const onClickAddTrack = () => {
        if(!id) return;
        dispatch(ShowModalCreateTrack({ show: true, showItemId: Number(id) }));
    }

    const onClickEditTrack = (track: ShowItemTrack) => {
       dispatch(ShowModalEditTrack({ show: true, track }));
    };

    if(!id) return null;

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6">
            <div className="flex flex-wrap justify-center">
                <div className="flex-none w-full max-w-[1024px]">
                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border">
                        <div className='flex flex-row justify-between items-center p-6 pb-0 mb-2 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent'>
                            <h6 className='mb-0'>{ t('Shared.Fields.Tracks') }</h6>
                            <button onClick={onClickAddTrack} className='btn btn-aurora'><i className="fa-solid fa-circle-plus mr-2"></i>{ t('Shared.Actions.Add') }</button>
                        </div>
                        <div className="flex-auto px-0 pt-0 pb-2">
                            <div className="p-0 overflow-x-auto">
                                <table className="items-center w-full mb-0 align-top border-gray-200 text-slate-500">
                                    <thead className="align-bottom">
                                        <tr>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ID') }</th>
                                            <th className="px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Title') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Provider') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.ContentType') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Visibility') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.SortOrder') }</th>
                                            <th className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">{ t('Shared.Fields.Created') }</th>
                                            <th className="px-6 py-3 font-semibold capitalize align-middle bg-transparent border-b border-gray-200 border-solid shadow-none tracking-none whitespace-nowrap text-slate-400 opacity-70"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { items.length > 0 ? 
                                            items.map((s: ShowItemTrack) => {
                                                return (
                                                    <tr key={s.id}>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ s.id }</p>
                                                        </td>
                                                        <td className="px-6 py-2 pl-0 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <h6 className="mb-0 leading-normal text-sm">{ s.title }</h6>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ t(`Shared.ShowItemTrackProviders.${s.provider}`) }</p>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ t(`Shared.ShowItemTrackContentTypes.${s.contentType}`) }</p>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ !s.hidden ? <span className='text-green-600'><i className="fa-solid fa-badge-check"></i></span> :  <span className='text-red-600'><i className="fa-sharp fa-solid fa-circle-xmark"></i></span> }</p>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <p className="mb-0 font-semibold leading-tight text-xs text-center">{ s.sortOrder }</p>
                                                        </td>
                                                        <td className="px-6 py-2 text-center align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span className="mb-0 font-semibold leading-tight text-xs text-slate-400 text-center">{ s.createdAt ? new Date(s.createdAt).toLocaleString() : '-'  }</span>
                                                        </td>
                                                        <td className="px-6 py-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                                                            <span onClick={onClickEditTrack.bind(null, s)} className="font-semibold leading-tight text-slate-400"><i className="fa-solid fa-pen-to-square"></i></span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        :
                                        <>
                                            <tr>
                                                <td colSpan={8}><p className='text-center mt-5'>{ t('Shared.Text.ThereAreNoItems') }</p></td>
                                            </tr>
                                        </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreateTrackModal/>
           <EditTrackModal/>
        </div>
    );
}

export default React.memo(ListTrack);
