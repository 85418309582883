import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';

import Pagination from 'components/Pagination';

import { usePagination } from 'hooks/page';
import { useAppDispatch } from 'store/hooks';
import { SetLoading } from 'store/layout.slice';

import { Log } from 'types/Log';
import { User } from 'types/User';
import { AxiosClient } from 'utils/axios';
import { MakeTextLang } from 'utils/string';
import { ShowModalLogDetail } from 'store/log.page.slice';
import SettingMaintenance from './Components/SettingMaintenance';
import { ESystemSettingKey } from 'types/Setting';
import { SetPlatformSettingList } from 'store/setting.page.slice.';

const platformSettingFetchList: string[] = [
    ESystemSettingKey.MaintenanceMode,
    ESystemSettingKey.MaintenanceMessage
];

function SettingListing() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [fetched, setFetched] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if(fetched || loading) return; // Skip
        
        setLoading(true);
        dispatch(SetLoading(true));

        // Platform
        Promise.all([
            AxiosClient.get('/system/settings', { params: { keys: platformSettingFetchList.join(',') } })
        ]).then((res: AxiosResponse[]) => {
            dispatch(SetPlatformSettingList(res[0].data));

            setFetched(true);
            setLoading(false);
            dispatch(SetLoading(false));
        }).catch((err: AxiosError) => {
            console.error(err);

            setLoading(true);
            dispatch(SetLoading(false));
        })

    }, [dispatch, loading, fetched]);

    return (
        <div className="fade-in w-full px-3 md:px-6 py-6 mx-auto">
            <h5 className='px-3 mb-3'>Platform Settings</h5>
            <div className='grid grid-cols-3 gap-3 mb-3'>
                <div className='col-span-3 md:col-span-1'>
                    <SettingMaintenance/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(SettingListing);
