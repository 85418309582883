export const AppRoutes = {
    // Home
    "/home": {
        title: "Lang:Pages.Home.Title",
        prePaths: [ ],
        back: null
    },
    // Layout/PageAndSection
    "/layout/pages": {
        title: "Lang:Pages.Layout.PageAndSection.PageListing.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.PageAndSection.Title" ],
        back: null
    },
    "/layout/pages/add": {
        title: "Lang:Pages.Layout.PageAndSection.PageAdd.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.PageAndSection.Title" ],
        back: { to: "/layout/pages" }
    },
    "/layout/pages/:id": {
        title: "Lang:Pages.Layout.PageAndSection.PageEdit.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.PageAndSection.Title" ],
        back: { to: "/layout/pages" }
    },
    "/layout/pages/edit-sections/:id": {
        title: "Lang:Pages.Layout.PageAndSection.PageEditSections.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.PageAndSection.Title" ],
        back: { to: "/layout/pages" }
    },
    // Layout/Featured
    "/layout/featured": {
        title: "Lang:Pages.Layout.Featured.FeaturedListListing.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.Featured.Title" ],
        back: null
    },
    "/layout/featured/add": {
        title: "Lang:Pages.Layout.Featured.FeaturedListAdd.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.Featured.Title" ],
        back: { to: "/layout/featured" }
    },
    "/layout/featured/:id": {
        title: "Lang:Pages.Layout.Featured.FeaturedListEdit.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.Featured.Title" ],
        back: { to: "/layout/featured" }
    },
    "/layout/featured/edit-items/:id": {
        title: "Lang:Pages.Layout.Featured.FeaturedListEditItems.Title",
        prePaths: [ "Lang:Pages.Layout.Title", "Lang:Pages.Layout.Featured.Title" ],
        back: { to: "/layout/featured" }
    },
    // Content/People
    "/content/people": {
        title: "Lang:Pages.Content.People.Listing.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.People.Title" ],
        back: null
    },
    "/content/people/add": {
        title: "Lang:Pages.Content.People.Add.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.People.Title" ],
        back: { to: "/content/people" }
    },
    "/content/people/:id": {
        title: "Lang:Pages.Content.People.Edit.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.People.Title" ],
        back: { to: "/content/people" }
    },
    // Content/Category
    "/content/categories": {
        title: "Lang:Pages.Content.Category.Listing.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Category.Title" ],
        back: null
    },
    "/content/categories/add": {
        title: "Lang:Pages.Content.Category.Add.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Category.Title" ],
        back: { to: "/content/categories" }
    },
    "/content/categories/:id": {
        title: "Lang:Pages.Content.Category.Edit.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Category.Title" ],
        back: { to: "/content/categories" }
    },
    // Content/Show
    "/content/shows": {
        title: "Lang:Pages.Content.Show.Listing.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Show.Title" ],
        back: null
    },
    "/content/shows/series-movie/add": {
        title: "Lang:Pages.Content.Show.AddSeriesMovie.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Show.Title" ],
        back: { to: "/content/shows" }
    },
    "/content/shows/series-movie/:id": {
        title: "Lang:Pages.Content.Show.EditSeriesMovie.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Show.Title" ],
        back: { to: "/content/shows" }
    },
    "/content/shows/season-episode/:id": {
        title: "Lang:Pages.Content.Show.EditSeasonEpisode.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Show.Title" ],
        back: { to: "/content/shows" }
    },
    "/content/shows/tracks/:id": {
        title: "Lang:Pages.Content.Show.ListTrack.Title",
        prePaths: [ "Lang:Pages.Content.Title", "Lang:Pages.Content.Show.Title" ],
        back: { to: "$back" }
    },
    // Community/User
    "/community/users": {
        title: "Lang:Pages.Community.User.Listing.Title",
        prePaths: [ "Lang:Pages.Community.Title", "Lang:Pages.Community.User.Title" ],
        back: null
    },
    "/community/users/:id": {
        title: "Lang:Pages.Community.User.Edit.Title",
        prePaths: [ "Lang:Pages.Community.Title", "Lang:Pages.Community.User.Title" ],
        back: { to: "/community/users" }
    },
    "/community/comments": {
        title: "Lang:Pages.Community.Comment.Listing.Title",
        prePaths: [ "Lang:Pages.Community.Title", "Lang:Pages.Community.Comment.Title" ],
        back: null
    },
    // System/Log
    "/system/setting": {
        title: "Lang:Pages.System.Setting.Listing.Title",
        prePaths: [ "Lang:Pages.System.Title", "Lang:Pages.System.Setting.Title" ],
        back: null
    },
    "/system/logs": {
        title: "Lang:Pages.System.Log.Listing.Title",
        prePaths: [ "Lang:Pages.System.Title", "Lang:Pages.System.Log.Title" ],
        back: null
    },
    // System/Log
    "/system/recycle-bin": {
        title: "Lang:Pages.System.RecycleBin.Listing.Title",
        prePaths: [ "Lang:Pages.System.Title", "Lang:Pages.System.RecycleBin.Title" ],
        back: null
    },
};