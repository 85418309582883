import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce'

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ESystemSettingKey } from 'types/Setting';
import { AxiosClient } from 'utils/axios';
import { UpdatePlatformSetting } from 'store/setting.page.slice.';

function SettingMaintenance() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { register, handleSubmit, control, setValue, getValues, watch } = useForm();

    const platformSettings = useAppSelector((state) => state.pageSetting.platformSettings);

    useEffect(() => {
        let mode = platformSettings.find((s) => s.key == ESystemSettingKey.MaintenanceMode);
        let message = platformSettings.find((s) => s.key == ESystemSettingKey.MaintenanceMessage);

        setValue('mode', (mode && mode.value == '1') ? true : false);
        setValue('message', message ? message.value : "")
    }, [platformSettings]);

    const onChangeMode = async () => {
        let newMode: boolean = getValues('mode');

        try {
            let { data } = await AxiosClient.patch(`/system/settings`, {
                "key": ESystemSettingKey.MaintenanceMode,
                "value": newMode == true ? '1' : '0'
            });

            dispatch(UpdatePlatformSetting({ key: ESystemSettingKey.MaintenanceMode, value: data.value }));
        } catch(e) {
            console.error(e);

            let mode = platformSettings.find((s) => s.key == ESystemSettingKey.MaintenanceMode);
            setValue('mode', (mode && mode.value == '1') ? true : false);
        }

    }

    const onChangeMessage = async () => {
        let newMessage: boolean = getValues('message');

        try {
            let { data } = await AxiosClient.patch(`/system/settings`, {
                "key": ESystemSettingKey.MaintenanceMessage,
                "value": newMessage || ''
            });

            dispatch(UpdatePlatformSetting({ key: ESystemSettingKey.MaintenanceMessage, value: data.value }));
        } catch(e) {
            console.error(e);

            let message = platformSettings.find((s) => s.key == ESystemSettingKey.MaintenanceMessage);
            setValue('message', message ? message.value : "")
        }
    }

    const debouncedOnChangeMessage = debounce(onChangeMessage, 1500);
  
    return (
        <div className="w-full max-w-full px-3">
            <div className="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                <div className="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                    <h6 className="mb-0">{ t('Pages.System.Setting.Listing.Platform.Maintenance.Title') }</h6>
                </div>
                <div className="flex-auto p-4">
                    <div className="min-h-6 mb-0.5 block pl-0">
                        <input id="setting-maintenance-mode" className="mt-0.54 rounded-10 duration-250 ease-soft-in-out after:rounded-circle after:shadow-soft-2xl after:duration-250 checked:after:translate-x-5.25 h-5 relative float-left ml-auto w-10 cursor-pointer appearance-none border border-solid border-gray-200 bg-slate-800/10 bg-none bg-contain bg-left bg-no-repeat align-top transition-all after:absolute after:top-px after:h-4 after:w-4 after:translate-x-px after:bg-white after:content-[''] checked:border-slate-800/95 checked:bg-slate-800/95 checked:bg-none checked:bg-right" type="checkbox" { ...register("mode", { onChange: onChangeMode }) }/>
                        <label htmlFor="setting-maintenance-mode" className="w-4/5 mb-0 ml-2 overflow-hidden font-normal cursor-pointer select-none text-sm text-ellipsis whitespace-nowrap text-slate-500" id="flexSwitchCheckDefault">{ t('Pages.System.Setting.Listing.Platform.Maintenance.Enabled') }</label>
                    </div>
                    <label className="mb-2 ml-1 font-bold text-xs text-slate-700">{ t('Shared.Fields.Message') }</label>
                    <div>
                        <textarea rows={3} className="input input-textbox"  { ...register("message", { maxLength: 1024, onChange: debouncedOnChangeMessage }) }/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SettingMaintenance);