
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISetting } from "types/Setting";

export interface SettingPageState {
  platformSettings: ISetting[];
}

const initialState: SettingPageState = {
  platformSettings: []
};

export const SettingPageSlice = createSlice({
  name: "pageSetting",
  initialState,
  reducers: {
    SetPlatformSettingList(state, action: PayloadAction<ISetting[]>) {
      state.platformSettings = action.payload;
    },
    UpdatePlatformSetting(state, action: PayloadAction<ISetting>) {
      let setting = state.platformSettings.find((s) => s.key == action.payload.key)
      if(setting) setting.value = action.payload.value;
    },
  }
});

export const { 
  SetPlatformSettingList, UpdatePlatformSetting
} = SettingPageSlice.actions;

export default SettingPageSlice.reducer;